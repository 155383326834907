// import React from "react"
// import { Link } from "gatsby"
// import FluidImage from "./FluidImage"
// import { blogURI } from "../../globals"
//
// const PostEntry = ({ post }) => {
//   const { uri, title, featuredImage, excerpt } = post
//
//   return (
//     <div style={{ marginBottom: "30px" }}>
//       <header>
//         <Link to={`${blogURI}/${uri}/`}>
//           <h2 style={{ marginBottom: "5px" }}>{title}</h2>
//           <FluidImage image={featuredImage} style={{ margin: 0 }} />
//         </Link>
//       </header>
//
//       <div dangerouslySetInnerHTML={{ __html: excerpt }} />
//     </div>
//   )
// }
//
// export default PostEntry

import React from "react"
import { Link } from "gatsby"
import Image from "./FluidImage"
import { blogURI } from "../../globals"
import moment from "moment/moment"
import UniversalLink from "../components/UniversalLink"

const PostEntry = ({ post }) => {
  const {
    id,
    uri,
    title,
    featuredImage,
    excerpt,
    author,
    date,
    termSlugs,
  } = post

  // console.log(post)

  return (
    <div
      id={id}
      className={`news-news-item slide-in-blurred-top mix ${termSlugs}`}
      style={{
        marginBottom: "30px",
        BorderBottom: "10px solid",
        padding: "0px",
        minHeight: "200px",
        // background: "#645e9c",
        border: "0px solid #555",
        // borderRadius: "10px",
        display: "flex",
        alignItems: "flex-end",
      }}
    >
      <div className="news-item">
        <header>
          <UniversalLink
            to={`${blogURI}/${uri}/`}
            style={{ textDecoration: "none" }}
          >
            <Image image={featuredImage} style={{ margin: 0 }} />
            <h4
              style={{
                margin: "5px",
                padding: "20px",
                fontWeight: "700",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {title}
            </h4>
            <p
              style={{
                background: "#fff",
                color: "#333",
                fontSize: ".85rem",
                padding: "10px",
                fontWeight: "300",
                margin: "10px 20px 25px",
              }}
            >
              Posted {moment(date).format(`MMMM D, YYYY`)} by {author.name}
            </p>
          </UniversalLink>
        </header>
        {/* <div dangerouslySetInnerHTML={{ __html: excerpt }} /> */}
      </div>
    </div>
  )
}

export default PostEntry
