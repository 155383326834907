import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PageHeader from "../../components/pageheader"
import PostEntry from "../../components/PostEntry"
import Pagination from "../../components/Pagination"

const Blog = ({ pageContext }) => {
  const { nodes, pageNumber, hasNextPage, itemsPerPage, allPosts } = pageContext

  return (
    <Layout>
      <SEO title="Blog" description="Blog posts" keywords={[`blog`]} />
      <PageHeader
        subTitle="Equity Alliance MN"
        headingTitle="Equity Blog"
        theme="about"
      />
      <div className="container main-section">
        <div className="small-container">
          {/* <div className="controls">
            <label>Filter:</label>
            <button className="filter" data-filter="all">
              All
            </button>
            <button className="filter" data-filter=".student-programming">
              Student Programming
            </button>
            <button className="filter" data-filter=".events">
              Events
            </button>
            <button className="filter" data-filter=".professional-development">
              Professional Development
            </button>
          </div> */}
          <div className="news-news-grid" id="newsList">
            {nodes &&
              nodes.map(post => <PostEntry key={post.id} post={post} />)}
          </div>

          <Pagination
            pageNumber={pageNumber}
            hasNextPage={hasNextPage}
            allPosts={allPosts}
            itemsPerPage={itemsPerPage}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Blog
